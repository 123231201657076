import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { BaseService } from 'ngx-hjr-lib';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService extends BaseService<any> {
  constructor(afs: AngularFirestore, afg: AngularFireStorage) {
    super('channels', afs, afg);
  }
}
