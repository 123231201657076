<nav>
    <div class="brand">
        <a href="javascript:void(0)" [routerLink]="['/']">
            <img src="../../../../assets/img/logo.svg" width="180" />
        </a>
    </div>
    <div class="profile"></div>
    <!-- <a class="hamburger" href="javascript:void(0)"
        ><img src="../../../../assets/img/hamburger.svg"
    /></a> -->
</nav>
