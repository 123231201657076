import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutBlankComponent } from './modules/layout/components/layout-blank/layout-blank.component';
import { LayoutWithNavbarComponent } from './modules/layout/components/layout-with-navbar/layout-with-navbar.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('./modules/connections/connections.module').then(
                (m) => m.ConnectionsModule
            ),
    },
    {
        path: 'world-map',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('./modules/world-map/world-map.module').then(
                (m) => m.WorldMapModule
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
