import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ChannelsService } from './modules/channels/channels.service';
import { LayoutModule } from './modules/layout/layout.module';

import firebase from 'firebase/app';
import 'firebase/analytics';
import { WorldMapComponent } from './modules/components/world-map/world-map.component';

firebase.initializeApp(environment.firebaseConfig);
firebase.analytics();

@NgModule({
  declarations: [AppComponent, WorldMapComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    LayoutModule,
  ],
  providers: [{ provide: Window, useValue: window }, ChannelsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
