import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutBlankComponent } from './components/layout-blank/layout-blank.component';
import { RouterModule } from '@angular/router';
import { LayoutWithNavbarComponent } from './components/layout-with-navbar/layout-with-navbar.component';
import { NavbarComponent } from './components/navbar/navbar.component';

@NgModule({
    declarations: [
        LayoutBlankComponent,
        LayoutWithNavbarComponent,
        NavbarComponent,
    ],
    imports: [CommonModule, RouterModule],
})
export class LayoutModule {}
